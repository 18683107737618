<template>
  <b-card no-body>
    <b-card-header>
      <h3>Fleet List</h3>
    </b-card-header>

    <b-card-body>
      <div class="d-flex">
        <b-button
          v-b-modal.modal-center
          class="mr-2"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="onAddFleet"
        >
          <feather-icon icon="PlusIcon" class="mr-50" />
          Add Fleet
        </b-button>
        <b-button
          v-b-modal.modal-upload
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="info"
        >
          <feather-icon icon="UploadIcon" class="mr-50" />
          Upload
        </b-button>
      </div>

      <b-row>
        <b-col md="6">

        </b-col>
        <b-col md="3" sm="4" xs="4">
          <b-form-group
            label="Active"
            label-cols-sm="9"
            label-align-sm="right"
            label-size="md"
            label-for="filterStatus"
            class="mb-0"
          >
          
            <b-form-checkbox v-model="filterStatus" name="check-button" switch @change="fleetFilters" style="margin-top: 5px;">
                  <!-- <span>Active</span> -->
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col md="3" sm="8" xs="6">
          <b-form-group
            label="Filter"
            label-cols-sm="2"
            label-align-sm="left"
            label-size="md"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group>
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <div class="d-flex justify-content-between flex-wrap">
        <!-- sorting  -->
        <!-- <b-form-group
          label-align-sm="left"
          label-cols-sm="2"
          label-for="sortBySelect"
          class="mr-1 mb-md-0"
        >
        </b-form-group> -->

        <!-- <div style="display: inline-flex;"> -->
          <!-- <span>Not in service</span> -->
        <!-- <b-form-group
          label="Filter"
          label-cols-sm="2"
          label-align-sm="left"
          label-size="md"
          label-for="filterInput"
          class="mb-0"
        >
          <b-form-checkbox v-model="filterStatus" name="check-button" switch @change="fleetFilters"> -->
                <!-- <span>Active</span> -->
          <!-- </b-form-checkbox>
        </b-form-group> -->
        <!-- </div> -->
        <!-- filter -->
        
      </div>
    </b-card-body>
   
   

    <b-table
      striped
      hover
      responsive
      class="position-relative fleet-table"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
    >
      <template #cell(avatar)="data">
        <b-avatar :src="data.value" />
      </template>
      <template #cell(tractor_id)="data">
        <span v-b-modal.modal-center @click="onEdit(data.item)">{{
          data.item.tractor_id
        }}</span>
      </template>
      <template #cell(actions)="data">
        <b-button
          class="mt-1"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          title="Upload Documents"
          @click="openDocumentModal(data.item.id,data.item)"
          style="
            position: relative;
            padding: 8px;
            width: 31px;
            margin-top: 0px !important;
          "
          v-b-modal.modal-upload-files
        >
          <feather-icon
            icon="UploadIcon"
            class="mr-50"
            title="Upload Documents"
          />
        </b-button>

        <b-button
          class="mt-1"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="success"
          title="View All Documents"
          @click="openDocuments(data.item.id)"
          style="
            position: relative;
            padding: 8px;
            width: 31px;
            margin-top: 0px !important;
            margin-left: 10px;
          "
        >
          <feather-icon
            icon="EyeIcon"
            class="mr-50"
            title="View All Documents"
          />
        </b-button>

        <b-dropdown
          variant="link"
          no-caret
          :right="$store.state.appConfig.isRTL"
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>

          <b-dropdown-item v-b-modal.modal-center @click="onEdit(data.item)">
            <feather-icon icon="EditIcon" />
            <span class="align-middle ml-50">Edit</span>
          </b-dropdown-item>

          <b-dropdown-item @click="onRemove(data.item.id)">
            <feather-icon icon="TrashIcon" />
            <span class="align-middle ml-50">Delete</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>

    <!-- modal vertical center -->
    <b-modal
      id="modal-upload-files"
      size="lg"
      centered
      title="Upload Documents"
      ok-only
      ok-title="Cancel"
      ref="modal-upload-files"
    >
      <b-row>
        <b-col cols="8">
          <b-form-group label="Doc. Name" label-for="Doc. Name" label-cols-md="3">
            <b-form-input placeholder="Document Name" v-model="document_name"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row> 
      <b-row> 
        <b-col cols="8">
          <b-form-group label="Expiration date" label-for="Expiration date" label-cols-md="3">
          <b-form-datepicker
            id="datepicker-dateformat2"
            :date-format-options="{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }"
            locale="en"
            v-model="expiry_date"
            placeholder="Expiry Date"
          ></b-form-datepicker> </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="8">
          <b-form-group label="Upload File" label-for="Upload File" label-cols-md="3">
            <b-form-file v-model="document_file" @change="onFileChange" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="8">
          

          <b-form-group label="" label-for="" label-cols-md="3">
            <VueLoadingButton
            class="mr-2"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click.native="onUploadFleetDocument"
            :disabled="!this.selectedFile"
            :loading="isLoading"
            :styled="isStyled"
    >Upload</VueLoadingButton>


    <!-- <VueLoadingButton
            class="button btn-sm"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click.native="handleClick"
            :loading="isLoading"
            :styled="isStyled"
    >Upload</VueLoadingButton> -->

          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>

    <!-- modal vertical center -->
    <b-modal
      id="modal-upload"
      centered
      title="Upload Form"
      ok-only
      ok-title="Cancel"
    >
      <!-- info -->
      <b-alert variant="info" show>
        <div class="alert-body">
          <span>While uploading data, old data will be updated !</span>
        </div>
      </b-alert>

      <!-- Styled -->
      <b-form-file
        v-model="file"
        placeholder="Choose a file or drop it here..."
        drop-placeholder="Drop file here..."
      />

      <b-button
        class="mt-1"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        @click="onUploadFleet"
      >
        Upload CSV file
      </b-button>
      <xlsx-read :file="file" style="display: none">
        <xlsx-json :sheet="selectedSheet">
          <template #default="{ collection }">
            <input type="text" :value="onSetFleet(collection)" />
          </template>
        </xlsx-json>
      </xlsx-read>
    </b-modal>

    <b-modal
      size="lg"
      id="modal-center"
      centered
      title="FLEET INFO FORM"
      ok-title="Save"
      @ok="onSave"
      ok-only
    >
      <b-row>
        <b-col cols="6">
          <b-form-group
            label="Tractor"
            label-for="h-first-name"
            label-cols-md="4"
          >
            <b-form-input
              v-model="fleet.tractor_id"
              id="h-first-name"
              placeholder="Enter Tractor.."
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="IFTA Sticker #"
            label-for="h-first-name"
            label-cols-md="4"
          >
            <b-form-input
              v-model="fleet.ifta_sticker"
              id="h-first-name"
              placeholder="Enter IFTA Sticker.."
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Model"
            label-for="h-first-name"
            label-cols-md="4"
          >
            <b-form-input
              v-model="fleet.model"
              id="h-first-name"
              placeholder="Enter Model.."
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="VIN" label-for="h-first-name" label-cols-md="4">
            <b-form-input
              v-model="fleet.vin"
              id="h-first-name"
              placeholder="Enter VIN.."
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Year" label-for="h-first-name" label-cols-md="4">
            <b-form-input
              type="number"
              v-model="fleet.year"
              id="h-first-name"
              placeholder="Enter Year.."
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="License Plate"
            label-for="h-first-name"
            label-cols-md="4"
          >
            <b-form-input
              v-model="fleet.license_plate"
              id="h-first-name"
              placeholder="Enter License Plate.."
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="T Check"
            label-for="h-first-name"
            label-cols-md="4"
          >
            <b-form-input
              v-model="fleet.t_check"
              id="h-first-name"
              placeholder="Enter T Check.."
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Pre Pass"
            label-for="h-first-name"
            label-cols-md="4"
          >
            <b-form-input
              v-model="fleet.pre_pass"
              id="h-first-name"
              placeholder="Enter Pre Pass.."
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="QVI" label-for="h-first-name" label-cols-md="4">
            <b-form-input
              type="date"
              v-model="fleet.qiv"
              id="h-first-name"
              placeholder="Enter QVI.."
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="BIT" label-for="h-first-name" label-cols-md="4">
            <b-form-input
              type="date"
              v-model="fleet.bit"
              id="h-first-name"
              placeholder="Enter BIT.."
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Book Value"
            label-for="h-first-name"
            label-cols-md="4"
          >
            <b-form-input
              v-model="fleet.book_value"
              id="h-first-name"
              placeholder="Enter Book Value.."
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Domicile"
            label-for="h-first-name"
            label-cols-md="4"
          >
            <v-select
              v-if="this.flag"
              v-model="selectedDomicile"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title"
              :options="option"
            />
            <b-form-input
              v-if="!this.flag"
              v-model="fleet.domicile"
              id="h-first-name"
              placeholder="Enter Domicile.."
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Domicile Email "
            label-for="h-first-name"
            label-cols-md="4"
          >
            <b-form-input
              v-model="fleet.domicile_email"
              id="h-first-name"
              placeholder="Enter Domicile Email.."
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="VEDR" label-for="h-first-name" label-cols-md="4">
            <b-form-input
              v-model="fleet.vedr"
              id="h-first-name"
              placeholder="Enter VEDR.."
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="ELD" label-for="h-first-name" label-cols-md="4">
            <b-form-input
              v-model="fleet.eld"
              id="h-first-name"
              placeholder="Enter ELD.."
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="STL" label-for="h-first-name" label-cols-md="4">
            <b-form-input
              v-model="fleet.stl"
              id="h-first-name"
              placeholder="Enter STL.."
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="LEASE #"
            label-for="h-first-name"
            label-cols-md="4"
          >
            <b-form-input
              v-model="fleet.lease"
              id="h-first-name"
              placeholder="Enter LEASE.."
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Status"
            label-for="h-first-name"
            label-cols-md="4"
          >
            <v-select
              id="h-first-name"
              v-model="fleet.status"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="label"
              :options="statusOptions"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </b-card>
</template>

<script>
import {
  XlsxRead,
  XlsxTable,
  XlsxSheets,
  XlsxJson,
  XlsxWorkbook,
  XlsxSheet,
  XlsxDownload,
} from "vue-xlsx/dist/vue-xlsx.es.js";
import {
  BRow,
  BCol,
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCard,
  BCardHeader,
  BCardBody,
  BModal,
  BAlert,
  BFormFile,
  VBModal,
  BDropdown,
  BDropdownItem,
  BFormDatepicker,
  BFormCheckbox
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import axios from "axios";
import moment from "moment";
import { serverUri } from "@/config";
import { getUserData } from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import VueLoadingButton from 'vue-loading-button';

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BCardHeader,
    BCardBody,
    BModal,
    BAlert,
    BFormFile,
    BDropdown,
    BDropdownItem,
    XlsxRead,
    XlsxTable,
    XlsxSheets,
    XlsxJson,
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
    vSelect,
    BFormDatepicker,
    BFormCheckbox,
    VueLoadingButton
  },
  data() {
    return {
      isLoading: false,
      isStyled: true,
      filterStatus: true,
      expiry_date: "",
      document_name: "",
      document_file: "",
      selectedFile: "",
      tractorNumber: "",
      progress: 0,
      file: null,
      perPage: 5,
      pageOptions: [5, 10, 15, 25],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        {
          key: "domicile",
          label: "DOMICILE",
          sortable: true,
        },
        {
          key: "tractor_id",
          label: "Tractor #",
          sortable: true,
        },
        {
          key: "lease",
          label: "LEASE #",
          sortable: true,
        },
        {
          key: "stl",
          label: "STL",
          sortable: true,
        },
        {
          key: "year",
          label: "YEAR",
          sortable: true,
        },
        {
          key: "model",
          label: "MODEL",
          sortable: true,
        },
        {
          key: "vin",
          label: "VIN",
          sortable: true,
        },
        {
          key: "t_check",
          label: "T_CHECK",
          sortable: true,
        },
        {
          key: "actions",
          label: "ACTION",
        },
      ],
      /* eslint-disable global-require */
      items: [],
      selectedDomicile: { title: "" },
      option: [],
      fleet: {
        tractor_id: null,
        ifta_sticker: null,
        model: null,
        vin: null,
        year: null,
        license_plate: null,
        t_check: null,
        pre_pass: null,
        qiv: null,
        bit: null,
        book_value: null,
        domicile: null,
        domicile_email: null,
        vedr: null,
        eld: null,
        stl: null,
        lease: null,
        status: null,
      },
      flag: 0,
      tractorDetails :{},
      selectedSheet: "Sheet1",
      collection: [],
      fleetlist: [],
      assignee: [],
      sheets: [
        {
          name: "SheetOne",
          data: [
            {
              c: 2,
            },
          ],
        },
      ],
      statusOptions: [
        { title: "Active", label: "Active" },
        { title: "Not in Service", label: "Not in Service" },
      ],
    };
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({
          text: f.label,
          value: f.key,
        }));
    },
  },
  created() {
    if (screen.width <= 400) {
      let forDeletion = ["stl", "year", "model", "vin", "t_check"];
      this.fields = this.fields.filter(
        (item) => !forDeletion.includes(item.key)
      );
    }
  },
  mounted() {
    this.getFleetUsers();
    // Set the initial number of items
    axios
      .post(`${serverUri}/fleet/getfleet`, {
        company: getUserData().company_id,
        status:this.filterStatus == true ? 'Active' : 'IN-Active'
      })
      .then((res) => {
        res.data.map((item) => {
          // item.bit = new Date(item.bit).toLocaleDateString();
          item.bit = this.getFormattedDate(item.bit);
          item.qiv = this.getFormattedDate(item.qiv);
        });
        this.items = res.data;
        this.totalRows = this.items.length;
      });
  },
  methods: {

    handleClick() {
      console.log("click"); /* eslint-disable-line */
      this.isLoading = true;
      setTimeout(() => (this.isLoading = false), 300000);
    },

    fleetFilters()
    {
      axios
      .post(`${serverUri}/fleet/getfleet`, {
        company: getUserData().company_id,
        status:this.filterStatus == true ? 'Active' : 'IN-Active'
      })
      .then((res) => {
        res.data.map((item) => {
          // item.bit = new Date(item.bit).toLocaleDateString();
          item.bit = this.getFormattedDate(item.bit);
          item.qiv = this.getFormattedDate(item.qiv);
        });
        this.items = res.data;
        this.totalRows = this.items.length;
      });
    },
    checkStatus(status)
    {
        return status === undefined ? false : true;
    },
    getFleetUsers()
    {
      this.$http
        .get(`${serverUri}/users/allfleetuser/${getUserData().company_id}`)
        .then((response) => {
          this.data = response.data;
            response.data.map((item) => {
              this.assignee.push({
                fullName: item.name,
                key: item.id,
              });
          });
        });
    },
    openDocuments(id) {
      this.$router.push({ name: "apps-fleet-documents", params: { id: id } });
    },
    openDocumentModal(tid,details) {
      this.tractorNumber = tid;
      this.tractorDetails = details
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onSetFleet(data) {
      this.fleetlist = data;
    },
    onFileChange(e) {
      const selectedFile = e.target.files[0];
      this.selectedFile = selectedFile;
      this.progress = 0;
    },
    onUploadFleetDocument() {
      const formData = new FormData();
      formData.append("file", this.selectedFile); // appending file
      formData.append("tractor_id", this.tractorNumber); // appending file
      formData.append("expiry_date", this.expiry_date); // appending file
      formData.append("document_name", this.document_name); // appending file
      formData.append("document_file", this.document_file); // appending file

      var d = new Date();
      const LastDate = moment(d.setDate(d.getDate() + 30)).format("YYYY-MM-DD");

      this.isLoading = true;
   
      axios
        .post(`${serverUri}/fleet/fleet_upload_document`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if(res.data.status)
          {
            if(this.expiry_date <= LastDate) 
            {
              this.addDocumentExpiryTask(this.tractorDetails.tractor_id,this.tractorDetails.model,this.tractorDetails.year,this.expiry_date);
            } 

            
            this.showToast(
              "success",
              "Success",
              "The Document File has been uploaded"
            );

            this.$refs["modal-upload-files"].hide();
            this.selectedFile = "";
            this.tractorNumber = "";
            this.expiry_date = "";
            this.document_name = "";
            this.document_file = "";

            this.isLoading = false;


          } else {

            this.showToast(
              "danger",
              "Warning",
              res.data.message
            );

            this.isLoading = false;


          }
        });

      // axios
      //   .post(`${serverUri}/fleet/uploadfleetdocument`, formData,
      //   {
      //     onUploadProgress: (ProgressEvent) => {
      //       let progress =
      //         Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100) +
      //         "%";
      //       this.progress = progress;
      //     },
      //   })
      //   .then((res) => {
      //     console.log(res);
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
      // axios
      //     .post(`${serverUri}/fleet/uploadfleet`, {
      //         data: this.fleetlist,
      //         company: getUserData().company_id,
      //     })
      //     .then((res) => {
      //         if (res.data.length) {
      //             res.data.map((item) => {
      //                 item.bit = new Date(item.bit).toLocaleDateString();
      //                 item.qiv = new Date(item.qiv).toLocaleDateString();
      //             });
      //             this.items = res.data;

      //             this.showToast(
      //                 "success",
      //                 "Success",
      //                 "The csv file has been uploaded"
      //             );
      //         }
      //     });
    },

    addDocumentExpiryTask(vehicleName,vehicleModel,vehicleYear,expiryDate)
    {
          var d = new Date();
	        const DueDate = moment(d.setDate(d.getDate() + 1)).format("YYYY-MM-DD");
          const Tags = [{title:'High',label:'High'}];
          const Assignee = this.assignee;

          axios
                .post(`${serverUri}/todo/add/${getUserData().id}`, {
                  title: 'Insurance Document of '+vehicleName+' ('+vehicleModel+'-'+vehicleYear+') will be exipre on '+expiryDate,
                  status : 'pending',
                  dueDate : DueDate,
                  assignee: Assignee,
                  tags: Tags,
                  description: 'Please renew this document before expiry date',
                  company: getUserData().company_id,

                })
                .then((res) => {
                if (res.data[0]) 
                {
                    return true;
                } else {
                    return false;
                }
              });

    },
    onUploadFleet() {
      axios
        .post(`${serverUri}/fleet/uploadfleet`, {
          data: this.fleetlist,
          company: getUserData().company_id,
        })
        .then((res) => {
          if (res.data.length) {
            res.data.map((item) => {
              item.bit = new Date(item.bit).toLocaleDateString();
              item.qiv = new Date(item.qiv).toLocaleDateString();
            });
            this.items = res.data;

            this.showToast(
              "success",
              "Success",
              "The csv file has been uploaded"
            );
          }
        });
    },
    onSave() {
      if (this.flag) {
        this.fleet.domicile = this.selectedDomicile.title;


        if(this.fleet.status.title == undefined)
        {
          this.fleet.status = this.fleet.status;
        } else {
          this.fleet.status = this.fleet.status.title;

        }
        // console.log(this.fleet);

        axios.post(`${serverUri}/fleet/updatefleet`, this.fleet).then((res) => {
          if (res.data.affectedRows) {
            this.showToast("success", "Success", "The fleet has been saved.");
          } else {
            this.showToast(
              "warning",
              "Failure",
              "The updating functions is failed."
            );
          }
        });

      } else {
        if (this.fleet.tractor_id)
          axios
            .post(`${serverUri}/fleet/savefleet`, {
              ...this.fleet,
              company: getUserData().company_id,
              status: this.fleet.status ? this.fleet.status.title : "",
            })
            .then((res) => {
              res.data.map((item) => {
                item.bit = new Date(item.bit).toLocaleDateString();
                item.qiv = new Date(item.qiv).toLocaleDateString();
              });
              this.items = res.data;

              this.showToast("success", "Success", "The fleet has been saved.");
            });
      }

      this.flag = 0;

      axios
      .post(`${serverUri}/fleet/getfleet`, {
        company: getUserData().company_id,
        status:this.filterStatus == true ? 'Active' : 'IN-Active'
      })
      .then((res) => {
        res.data.map((item) => {
          // item.bit = new Date(item.bit).toLocaleDateString();
          item.bit = this.getFormattedDate(item.bit);
          item.qiv = this.getFormattedDate(item.qiv);
        });
        this.items = res.data;
        this.totalRows = this.items.length;
      });
    },
    onAddFleet() {
      this.flag = 0;
      Object.keys(this.fleet).forEach((key) => {
        this.fleet[key] = null;
      });
    },
    onEdit(data) {
      const that = this;
      const tempArr = [];
      this.option = [];
      this.flag = 1;
      this.fleet = Object.assign({}, data); //assign value not reference
      this.selectedDomicile.title = data.domicile;
      this.items.map((item) => {
        tempArr.push(item.domicile);
      });

      const unique = [...new Set(tempArr)];
      unique.forEach(function (item) {
        that.option.push({ title: item });
      });
    },
    onRemove(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          axios.get(`${serverUri}/fleet/remove/${id}`).then((res) => {
            this.items.map((item, index) => {
              if (item.id === id) this.items.splice(index, 1);
            });

            this.showToast("success", "Success", "The fleet has been removed");
          });

          this.$swal({
            icon: "success",
            title: "Deleted!",
            text: "Your file has been deleted.",
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      });
    },
    showToast(variant, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: "BellIcon",
          text: text,
          variant,
        },
      });
    },
    getFormattedDate(date) {
      return moment(date).format("YYYY-MM-DD");
    },
  },
};
</script>

<style>
.fleet-table th,
td {
  white-space: nowrap;
}
.loading {
  position: absolute;
  left: inherit !important;
  top: inherit !important;
  /* width: 55px; */
  width: auto !important;
  height: 55px;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border: 3px solid transparent;
}
[dir=ltr] .spinner {
    animation: inherit !important;
}
.vue-loading-button {
  background : #7367f0 !important;
    font-weight: 500;
    line-height: 1rem !important;
    padding: 0.786rem 0.5rem !important;
    font-size: 1rem !important;
}
</style>
